<template>
  <div>
    <top></top>
    <!-- 轮播图+个人中心 -->
    <div class="content-box">
      <div class="content">
        <!--轮播图 -->
        <div class="slideshow">
          <div class="block">
            <el-carousel height="400px" trigger="click" class="block_one">
              <el-carousel-item v-for="item in arr" :key="item.id">
                <img :src="item.img" alt="" @click="toLink(item.link)" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 个人中心 -->
        <div class="personal-center">
          <!-- 头像 -->
          <div class="head-box">
            <div class="head-img">
              <div class="head-content">
                <img
                  :src="urlimga"
                  alt=""
                  @click="portrait"
                  style="cursor: pointer"
                />
                <!-- <img src="../assets/home/1.png" alt=""> -->
              </div>
            </div>
          </div>
          <!-- 游客 -->
          <div class="visitor_box" v-show="tourist">
            <div class="visitor">
              <div class="visitor-font">
                <span>游客:123456789</span>
              </div>
            </div>
            <!-- 登录注册 -->
            <div class="enter-login-button">
              <div class="enter-login-button-box">
                <button @click="qq">登录/注册</button>
              </div>
            </div>
          </div>
          <!-- 已登录后的样式 -->
          <div class="Is_now_box" v-show="!tourist">
            <!-- 昵称 -->
            <div class="nickname_box">
              <div class="nickname">
                <!-- <i><img src="../assets/home/240.png" alt="" /></i> -->
                <p>{{ uesraname }}</p>
              </div>
            </div>
            <!-- 手机号 -->
            <div class="account_box">
              <p>{{ cellphonea }}</p>
            </div>
            <!-- 会员 -->
            <div class="account_box">
              <p>
                   &nbsp;
                  <!-- {{ member }} -->
              </p>
            </div>
            <!-- 收藏 订单 福利  -->
            <div class="I_bottom">
              <!-- 收藏 -->
              <div class="I_bottom_content" @click="coliect">
                <ul>
                  <li>
                    <i><img src="../assets/home/60.png" alt="" /></i>
                  </li>
                  <li>收藏</li>
                </ul>
              </div>

              <!-- 福利 -->
             <!--  <div class="I_bottom_content" @click="welfare">
                <ul>
                  <li>
                    <i><img src="../assets/home/62.png" alt="" /></i>
                  </li>
                  <li>福利</li>
                </ul>
              </div> -->
              <!-- 订单 -->
              <div class="I_bottom_content" @click="order">
                <ul>
                  <li>
                    <i><img src="../assets/home/61.png" alt="" /></i>
                  </li>
                  <li>订单</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索框 -->
    <div :class="scrollTop > 461 ? 'fixedSearch' : ''" class="seek">
      <div :class="scrollTop > 461 ? 'fixedSearchNext' : ''" class="seek-input">
        <div class="seek-box">
          <div class="seek-one">
            <div class="seek-one-icon">
              <img src="../assets/home/seek.png" alt="" />
            </div>
            <div class="seek-one-input">
              <input
                type="font"
                placeholder="搜索您想要的内容"
                v-model="keywords"
                @keyup.enter="toSearch"
              />
            </div>
            <div class="seek-one-button" @click="toSearch">
              <button @click="toSearch">搜索</button>
            </div>
          </div>
        </div>
        <div class="seek_input_font">
          <ul>
            <li
              v-for="(item, index) in Populartags"
              :key="index"
              @click="hotTg(item)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- ------------------ -->
    <!-- 短剧本 -->
    <div class="play-box">
      <div class="play-content">
        <!-- 标题 -->
        <div class="play-title">
          <i><img src="../assets/home/3.png" alt="" /></i>
          <h1>短剧本</h1>
          <!-- <p style="color: #666666;">短剧本标题宣传语</p> -->
        </div>
        <!-- 选项卡 -->
        <div class="tab">
          <ul>
            <li
              v-for="(item, index) in btns"
              :key="index"
              :class="activeIndex == index ? 'btnAtive' : ''"
              @click="handleToggle(index, item.id)"
            >
              {{ item.title }}
            </li>
            <li @click="entrance">1~{{gengduoNum[geng]}}</li>
          </ul>
        </div>
        <div class="container">
          <div>
            <div
              class="box"
              v-for="play in playa"
              :key="play.id"
              @click="toText(play.id)"
            >
              <div class="tab-1">
                <!-- 标题 -->
                <div class="tab-title-font">
                  <!-- 前面的竖线 -->
                  <div class="tab-one"></div>
                  <div class="tab-title">
                    <p>{{ play.title }}</p>
                  </div>
                </div>
                <!-- 文字内容 -->
                <div class="tab-font">
                  <p
                    v-html="colorkeyword(play.description)"
                    v-if="play.description != null && play.description != ''"
                  ></p>
                  <p v-html="colorkeyword(play.content)" v-else></p>
                </div>
                <!-- 标签 -->
                <div class="label">
                  <div v-for="(itema, index) in play.tagestitle" :key="index">
                    {{ itema.name }}
                  </div>
                </div>
                <div class="moreBtn">查看更多</div>
                <!-- 个人信息 -->
                <div class="personal">
                  <p><img src="../assets/home/1.png" alt="" /></p>
                  <p>{{ play.useradmin }}</p>
                  <p>{{ play.updated_at }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 短视频定制 -->
    <div class="customization">
      <!-- 短视频定制的背景 -->
      <div class="customization-background">
        <img src="../assets/home/4.png" alt="" />
      </div>
      <!-- 短视频定制的内容 -->
      <div class="customization-box">
        <div class="customization-content">
          <!-- 短视频定制内容左边文字 -->
          <div class="customization-font">
            <!-- 图标+标题 -->
            <div class="customization-font-title">
              <i><img src="../assets/home/6.png" alt="" /></i>
              <h1>短视频定制服务</h1>
            </div>
            <!-- 描述 -->
            <div class="describe">
              <p>一站式&全方位定制服务</p>
            </div>
            <!-- 分割线 -->
            <div class="cut-off_rule-box">
              <div class="cut-off_rule"></div>
            </div>
            <!-- 下面的图标 -->
            <div class="customization-icon">
              <div>
                <i><img src="../assets/home/7.png" alt="" /></i>
                <p>剧本定制</p>
              </div>
              <div>
                <i><img src="../assets/home/8.png" alt="" /></i>
                <p>有声录制</p>
              </div>
              <div>
                <i><img src="../assets/home/9.png" alt="" /></i>
                <p>短视频制作</p>
              </div>
            </div>
          </div>
          <!-- 短视频定制内容右边的视频 -->
          <div class="customization-mp4">
            <video :src="arrmp" controls="controls"></video>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="customization-button">
          <button @click="toCustomization">查看详情 ></button>
        </div>
      </div>
    </div>
    <!-- 代运营 -->
    <div class="thirdpartnar-box">
      <div class="thirdpartnar-content">
        <!-- 标题 -->
        <div class="thirdpartnar-title">
          <i><img src="../assets/home/10.png" alt="" /></i>
          <h1>代运营案例</h1>
       
        </div>
        <!-- 内容 -->
        <div class="three">
          <div class="thirdpartnar-content-box">
            <div class="thirdpartnar-content-one">
                <div class="list_lfinfo">
                  <ol class="lfinoutinfo">
                    <li :class="num==r?' listbannerinfo listbannerinfotwo':'listbannerinfo'" v-for="(item, r) in leftteamifno" :key="r" @click="tabcardbtn(r)" >
                      <div class="listheadimg">
                        <img :src="item.urlimg" alt="">
                      </div>
                      <div class="listheadmesage">
                        <p>{{item.title}}</p>
                        <p>{{item.subtitle}}</p>
                      </div>
                    </li>              
                  </ol>
                </div>

                <div class="list_rfbanner" >
                  <el-carousel indicator-position="outside"  :interval="4000"   type="card" height="90%"   :autoplay="false" ref="cursonal" >
                    <el-carousel-item v-for="(item,i) in bannerinfo" :key="i" >
                     <img :src="item.img" alt="" style="border-radius: 15px;">                     
                    </el-carousel-item>
                  </el-carousel>
                </div>
             
            </div>
          </div>
        
        </div>      
      </div>
            <!-- 客户评价 -->
            <div class="evaluatebox_cebte">
              <div class="evaluatebox">
                <div class="evaluation_title">
                  <p>客户评价</p>
                  <p>Customer evaluation</p>
                </div>
                <div class="evaluateboxbanner">
                  <el-carousel :interval="5000" arrow="always"  indicator-position="none" >
                    <el-carousel-item v-for="(item, i) in userevaluate" :key="i" id="courbannebox">
                      <div class="list_bannerbox">
                       <div class="centheadimg">
                        <img :src="item.thumb" alt="">
                       </div>
                        <div class="conteninfo">
                          <p>{{item.title}}</p>
                          <p>{{item.content}}</p>
                        </div>
                        <div class="douyinmesg">
                        <div class="imgbox">
                          <img src="../assets/douyin.png" alt="">
                          <img src="../assets/kuaishou.png" alt="">
                        </div>
                        </div>
                      </div>
                    
                    </el-carousel-item>
                  </el-carousel>
                </div>  
      
      
              </div>
             </div>
    </div>

    <!-- 总体数据 -->
    <div class="industry-information-box">
      <div class="industry-information-content">
        <!-- 标题 -->
        <div class="thirdpartnar-title">
          <i><img src="../assets/home/10.png" alt="" /></i>
          <h1>总体数据</h1>
        </div>
        <div class="datainfbox">
          <div class="listinfdata" @mouseover="mouseover(1)" @mouseout="mouseout(1)">
             <div class="centedata">
               <div class="listtihaindle">
                 <img src="../assets/aaa/hand.png" alt="">
                 <p>累计服务客户</p>
               </div>
               <div class="listdatnumber">
                 <p>{{accumulate}}</p>
               </div>
               <div class="listdatetitme">
                 <p>仅3年我们服务各行业客户超36万人</p>
               </div>
             </div>
          </div>
          <div class="listinfdata" @mouseover="mouseover(2)" @mouseout="mouseout(2)">
            <div class="centedata">
              <div class="listtihaindle">
                <img src="../assets/aaa/pensal.png" alt="">
                <p>累计剧本定制</p>
              </div>
              <div class="listdatnumber">
                <p>{{drama}}</p>
              </div>
              <div class="listdatetitme">
                <p>累计撰写各类剧本超过9千万字</p>
              </div>
            </div>
         </div>
         <div class="listinfdata" @mouseover="mouseover(3)" @mouseout="mouseout(3)">
          <div class="centedata">
            <div class="listtihaindle">
              <img src="../assets/aaa/zan.png" alt="">
              <p>客户满意度</p>
            </div>
            <div class="listdatnumber">
              <p>{{satisfaction}}%</p>
            </div>
            <div class="listdatetitme">
              <p>1700+专业编剧服务，客户满意度99%</p>
            </div>
          </div>
       </div>
        </div>
     
      </div>
    </div>

    <!-- 公司环境 -->

    <div class="environmentbox">
      <div class="compay_title" >
        <img src="../assets/home/10.png" alt="">
        <h1>公司环境</h1>
      </div>

      <div class="postioneviroment">
        <ul class="centeenvironment"  @mouseover="rumimgover" @mouseout="rumimgout">
         <li class="list_environm" v-for="(item, i) in listcompany" :key="i">
          <div class="outerimgcop">
            <img :src="item.thumb" alt="">
          </div>
           <p>{{item.title}}</p>
         </li>  
        </ul>
      </div>
     
    </div>
    <!-- 登录注册 -->
    <!-- 盒子 -->
    <div :class="{ enter_box: qwe, demo: asd }">
      <div class="enter"  @click.stop="ww('isBox')">
        <!-- 中间的盒子 -->
        <div class="enter_content">
          <!-- 关闭按钮 -->
          <div class="close_button_box"  @click.stop="ww('notBox')">
            <i><img src="../assets/home/166.png" alt="" /></i>
          </div>
          <!-- 左 -->
          <div class="enter_left">
            <img src="../assets/home/99.png" alt="" />
          </div>
          <!-- 右 -->
          <div class="box_asd">
            <div :class="{ enter_right: erbtn }">
              <!-- 二维码 -->
              <div class="QR_code_content">
                <!-- 标题 -->
                <div class="enter_title">
                  <p>微信登录</p>
                </div>
                <!-- 扫码并关注 -->
                <div class="enter_text_box">
                  <div class="enter_text">
                    <p>扫码并<span>关注我要短剧本</span>公众号，安全快捷登录</p>
                  </div>
                </div>
                <!-- 二维码 登录 验证码 -->
                <div class="QR_code_box">
                  <!-- 二维码 -->
                  <div class="QR_code">
                    <img :src="userimg" alt="" />
                  </div>
                </div>
                <!-- 扫码即为同意协议 -->
                <div class="enter_bottom_box">
                  <div class="enter_bottom">
                    <input checked type="checkbox" />
                    <label for=""
                      >扫码登录即表示您同意并遵守<span @click="agreement"
                        >用户协议</span
                      ></label
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 手机号注册 -->
            <div :class="{ rnter_righta: erbtna }" v-show="!phoneOk">
              <div class="QR_code_content">
                <!-- 标题 -->
                <div class="enter_title">
                  <p>绑定手机号码</p>
                </div>
                <!-- 扫码并关注 -->
                <div class="enter_text_box">
                  <div class="enter_text">
                    <p>您已关注公众号，绑定手机号码后即可注册成功</p>
                  </div>
                </div>
                <!-- 二维码 登录 验证码 -->
                <div class="QR_code_box">
                  <!-- 登录 -->
                  <div class="phone" style="border-radius: 25px">
                    <div class="number_icon">
                      <i><img src="../assets/home/130.png" alt="" /></i>
                    </div>
                    <div class="number-input" style="border-radius: 25px">
                      <input
                        type="tel"
                        name="text1"
                        maxlength="11"
                        v-model="deml"
                        placeholder="输入手机号码"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      />
                    </div>
                  </div>
                  <!-- 验证码 -->
                  <div class="verify">
                    <div class="verify_icon">
                      <i><img src="../assets/home/131.png" alt="" /></i>
                    </div>
                    <div class="verify-input">
                      <input
                        type="tel"
                        name="text1"
                        maxlength="6"
                        v-model="demla"
                        placeholder="输入手机验证码"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      />
                    </div>
                    <!-- 获取验证码按钮 -->
                    <div class="verify_button">
                      <button @click="code" :disabled="isdisable">
                        {{ content }}
                      </button>
                    </div>
                  </div>
                  <!-- 登录完成按钮 -->
                  <div class="complete">
                    <button @click="accomplish">完成</button>
                  </div>
                </div>
                <!-- 扫码即为同意协议 -->
                <div class="enter_bottom_box">
                  <div class="enter_bottom">
                    <input checked type="checkbox" />
                    <label for=""
                      >扫码登录即表示您同意并遵守<span @click="agreement"
                        >用户协议</span
                      ></label
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 客服二维码图片 -->
            <div class="kferwm" v-show='is_pop==1 && phoneOk'>
              <img :src="kfData.img" alt="">
              <div class="text" v-if="kfData">
                <div v-for="(item,index) of kfData.title.split('|')" :key="index">
                  <div v-show="index==0" class="textOne">
                    <img src="../assets/wx.png" alt="">
                    <div>{{item}}</div>
                  </div>
                  <div v-show="index!=0">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 广告 -->
    <div class="advertising_box" v-if="isShow">
      <div class="advertising">
        <button class="occlude" @click="onclusa">×</button>
        <!-- 图片 -->
        <div class="advertising_image">
          <i><img src="../assets/home/vip.png" alt="" /></i>
        </div>

        <!-- 按钮 -->
        <div class="advertising_button">
          <button @click="kaitong">开通VIP</button>
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->
    <sidebar></sidebar>
    <bottom></bottom>
    <!-- 代运营获取报价点击后弹窗 -->
    <div :class="{ customer: showaaa, customera: showbbb }">
      <div class="entera">
        <!-- 中间的盒子 -->
        <div class="enter_contenta">
          <!-- 关闭按钮 -->
          <div class="close_button_box" @click="closeaa">
            <i><img src="../assets/home/166.png" alt="" /></i>
          </div>
          <div class="imgsrca">
            <img :src="colseimg" alt="" />
          </div>
          <p>添加客服获取报价</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    var timerli;
    import Qs from "qs";
    import axios from "axios";
    import {
        getIndexSearch,
        getIndexviedo,
        getIndeximg,
        getIndexplay,
        getIndexmessage,
        getIndexguild,
        getIndexcentent,
        getIndexbiao,
        Msgbdphone,
        Populartags,
    } from "../http.js";
    export default {
        inject: ["reload"],
        data() {
            return {
                bannerinfo: [], //代运营右侧轮播图
                leftteamifno: [], // 左侧信息
                erbtna: true, //手机号注册
                phoneOk: false, //手机号注册完成
                is_pop: 0, //1-显示客服二维码 0-不显示
                kfData: '', //客服信息
                gengduoNum: 0, //查看更多
                geng: 'prducttype', //选中导航
                erbtn: false,
                isdisable: false,
                btns: [],
                tab: [],
                activeIndex: 0,
                active: 0,
                qwe: true,
                asd: true,
                arr: [],
                arrmp: "",
                playa: [],
                messagea: [],
                messageThree: {},
                guilda: [],
                tabb: [],
                cent: {},
                three: [],
                keywords: "",
                scrollTop: 0,
                qrimg: [],
                resultList: [],
                dataa: [],
                confirm: true,
                confirma: true,
                userimg: "",
                phone: "",
                deml: "",
                demla: "",
                showaaa: true,
                showbbb: true,
                colseimg: [],
                tourist: true,
                register: true,
                ding: "",
                valuea: [],
                val: "",
                urlimga: require("../assets/home/1.png"),
                uesraname: "",
                cellphonea: "",
                member: "",
                judge: "",
                content: "发送验证码", // 按钮里显示的内容
                totalTime: 60, //记录具体倒计时时间
                valueb: [],
                shoujihao: "",
                clock: "",
                keybbb: [],
                vipNum: "", //会员次数
                isShow: false, //vip弹窗显隐
                ticket: "",
                Populartags: [], //热门段子
                looknum: 0, //观看次数
                num: 1, // li样式显示

                listcompany: [], // 公司环境
                userevaluate: [], // 客户评价

                accumulate: "", // 累计服务客户
                drama: "", // l累计剧本定制
                satisfaction: "", //客户满意度

                // img
                dataimg: {
                    // 1
                    first: ".././assets/aaa/hand.png",
                    //白素
                    firsttwo: ".././assets/aaa/headtwo.png",
                    // 2
                    firstacten: ".././assets/aaa/pensal.png",
                    //白素
                    firsttwoaccen: ".././assets/aaa/pensaltwo.png",

                    // 3
                    optionone: ".././assets/aaa/zan.png",
                    // 白色
                    optionactive: ".././assets/aaa/zantwo.png"
                }
            };
        },

        // 页面初始化加载
        created() {
            var infostr = sessionStorage.getItem("key");
            var keyaaa = JSON.parse(infostr);
            this.keyaaa = keyaaa;
        },
        mounted() {

            // 获取大数据
            axios.get(this.http + "/shopmini/wap_Bottom.html").then((res) => {
                this.accumulate = res.data.accumulate

                this.drama = res.data.drama
                    // 百分比
                this.satisfaction = res.data.satisfaction;
            });
            // 带运营
            this.$axios({
                    method: "post",
                    url: "/api/shopmini/wap_Indexagentcase.html",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: Qs.stringify(),
                })
                .then((res) => {

                    this.leftteamifno = res.data.data;
                    this.bannerinfo = res.data.right;
                })
                .catch(function(error) {
                });


            // 客户评价
            this.$axios({
                    method: "post",
                    url: "/api/shopmini/wap_Testimonials.html",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: Qs.stringify(),
                })
                .then((res) => {
                    this.userevaluate = res.data.data;

                })
                .catch(function(error) {
                });


            //公司环境

            this.$axios({
                    method: "post",
                    url: "/api/shopmini/wap_Environment.html",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: Qs.stringify(),
                })
                .then((res) => {
                    this.listcompany = res.data.data;
                })
                .catch(function(error) {
                });

            // 页面加载
            setTimeout(() => {
                this.imgrun(1)
            }, 1000)

            this.searcha();
            this.searchb();
            this.searchc();
            this.searchd();
            this.searche();
            // this.searchf();
            this.searchaa();
            this.qrcode();
            // 获取更多数据
            this.getGeng()
                // this.GetuserDEN();
                // this.cellphone();
            this.session();
            // 监听页面滚动事件
            window.addEventListener("scroll", this.showSearch);
            // 热门标签调用
            this.hotTag();
            // 获取is_pop 1-显示客服二维码 0-不显示
            this.is_pop = sessionStorage.getItem('is_pop');
            if (this.is_pop == 1) {
                this.getKf();
            }
        },
        watch: {
            resultList() {
                this.timer();
            },
        },

        methods: {
            // 移入
            mouseover(num) {
                // alert(num)
                if (num == 1) {

                }
            },
            // 移出
            mouseout(num) {

            },
            // 滚动图片移入
            rumimgover() {
                clearInterval(timerli);
            },
            // 滚动图片移出
            rumimgout() {
                this.imgrun(1)
            },
            // 图片停止
            imgrun(startOrend) {
                var bodyH = $('.postioneviroment').width();
                var boxH = $('.centeenvironment').width();
                var content = $('.centeenvironment').html();
                var chaH = boxH - bodyH;
                if (startOrend == 1) {
                    if (chaH > 0) {
                        let top = 0;
                        timerli = setInterval(() => {
                            top--;
                            $('.centeenvironment').css({
                                left: top
                            });
                            if ((top * -1) > chaH) {
                                content += $('.centeenvironment').html();
                                boxH = $('.centeenvironment').width();
                                chaH = boxH - bodyH;
                                $('.centeenvironment').html(content)
                            }
                        }, 10)
                    }
                } else {
                    clearInterval(timerli)
                }


            },

            tabcardbtn(n) {
                this.num = n;
                this.$refs.cursonal.setActiveItem(n)
            },
            // 获取更多数据
            getGeng() {
                this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Indextypenum.html",
                    }).then(({
                        data
                    }) => {
                        this.gengduoNum = data.data;
                    })
                    .catch(function(error) {
                    });
            },
            // 获取客服
            getKf() {
                this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Service.html",
                    }).then((res) => {
                        this.kfData = res.data.data[0];
                    })
                    .catch(function(error) {
                    });
            },
            // 热门标签点击事件
            hotTg(item) {
                this.keywords = item;
                this.toSearch();
                this.colorkeyword(item);
            },
            // 热门标签
            async hotTag() {
                let res = await Populartags();
                if (res.static == 1) {
                    this.Populartags = res.data;
                }
            },
            timer() {
                return setTimeout(() => {
                    this.GetuserDEN(); //这个就是我的接口
                }, 10000);
            },
            // 页面刷新
            winReload(cond) {
                window.location.reload();
            },
            //轮播图跳转
            toLink(e) {
                // window.location.href = e;
                window.open(e, "_blank");
            },
            // 开通vip弹窗确定按钮
            kaitong() {
                this.$router.push({
                    path: "/vip",
                });
            },
            //跳转详情
            async toText(id) {
                let that = this;
                that.tabAcitve(id);
                return
                // 没有扫过码
                /* if (that.valuea == null) {
                    that.qq();
                } */
                // 扫码过没有注册账号
                if (that.valuea.uid == "" || that.valuea.phone == "") {
                    that.$message({
                        message: "请注册账号",
                        type: "warning",
                        duration: 2000,
                    });
                    that.asd = false; //注册遮罩显示
                    // 手机号注册显示开始
                    that.erbtna = false;
                    that.erbtn = true;
                    // 手机号显示结束
                    return;
                }
                //普通会员
                if (that.valuea.class == 0) {
                    let that = this;
                    await axios
                        .post(
                            "/api/shopmini/wap_UserContents.html",
                            Qs.stringify({
                                uid: that.valuea.uid,
                                login_key: that.valuea.openid,
                            }), {
                                headers: {
                                    "Content-Type": "application/x-www-form-urlencoded",
                                },
                            }
                        )
                        .then((res) => {
                            if (res.data.snum > 0) {
                                that.tabAcitve(id);
                                return;
                            }
                            if (res.data.snum == 0) {
                                // 判断普通会员用户观看次数
                                that.$message({
                                    message: "今日浏览次数已用完",
                                    type: "warning",
                                });
                                that.isShow = true; //vip弹框显示
                                return;
                            }
                        });

                }

                //非普通会员无限看
                if (that.valuea.class > 0) {
                    that.tabAcitve(id);
                    return;
                }
            },


            // tab跳转
            tabAcitve(id) {
                //   //选项卡跳页面开始
                if (this.activeIndex == 0) {
                    this.$router.push({
                        name: "Text",
                        query: {
                            page: 2,
                            id,
                        },
                    });
                }
                if (this.activeIndex == 1) {
                    this.$router.push({
                        name: "Text",
                        query: {
                            page: 0,
                            id,
                        },
                    });
                }

                if (this.activeIndex == 2) {
                    this.$router.push({
                        name: "Text",
                        query: {
                            page: 1,
                            id,
                        },
                    });
                }
                // 选项卡结束
            },
            //跳转带运营
            toOperation() {
                this.$router.push({
                    name: "Operation",
                    query: {
                        page: 4,
                    },
                });
            },

            //跳转定制服务
            toCustomization() {
                this.$router.push({
                    name: "Customization",
                    query: {
                        page: 3,
                        tab: 3,
                    },
                });
            },
            //吸顶监听
            showSearch() {
                let scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                this.scrollTop = scrollTop;
            },
            //搜索
            async toSearch() {
                let keywords = this.keywords;

                if (keywords.trim().length <= 0) {
                    alert("请输入内容");
                    return;
                }
                this.$router.push({
                    name: "Search_page",
                    query: {
                        keywords: keywords,
                    },
                });
            },
            //视频
            async searcha() {
                let ship = await getIndexviedo();
                this.arrmp = ship.data[0];
            },
            //轮播图片
            async searchb() {
                var img = await getIndeximg();
                this.arr = img;
            },
            // 首页短剧本标题
            async searchaa() {
                var biao = await getIndexbiao();
                this.btns = biao;
            },
            // 首页短剧本
            async searchc(id) {
                var play = await getIndexplay(id);
                this.playa = play;
            },

            // 首页代运营
            async searchd() {
                var message = await getIndexmessage();
                // 截取最后一条数据
                let obj = message.data[2];
                this.messageThree = obj;
                message.data.pop();
                this.messagea = message.data;
                this.colseimg = this.messagea[0].Img1;
            },
            // 首页行业资讯
            async searche() {
                var guild = await getIndexguild();
                this.guilda = guild;
                // 一级标题
                this.tabb = this.guilda.gettop.title;
                // 二级标题
                this.tab = this.guilda.getsecond;
                this.searchf(guild.getsecond[0].id);
                // this.colseimg = this.messagea[0].Img1;
            },
            // 首页行业资讯列表内容
            async searchf(id) {
                var cententa = await getIndexcentent(id);
                this.cent = cententa.data.filter((item, index) => {
                    return index < 6;
                });
            },
            // 二维码
            async qrcode() {
                let that = this;
                let Base64 = require('js-base64').Base64;
                var referinfo = this.getUrlinfo("refer");
                var referbase = Base64.decode(referinfo);
                await this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_UserContent.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            data: this.userimg,
                            refer: referbase,
                        }),
                    })
                    .then(function(res) {
                        that.userimg = res.data.data;
                        that.ticket = res.data.ticket; //微信关注公众号唯一标识符
                    })
                    .catch(function(error) {
                    });
            },

            // 手机号验证码
            async cellphone() {
                let that = this;
                this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Sendmsg.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            tel: that.deml,
                            uid: that.val == "" ? that.valuea.uid : that.val,
                        }),
                    })
                    .then(function(res) {
                        if (res.data.status == 1) {
                            that.$message({
                                message: res.data.msg,
                                type: "success",
                            });
                        } else {
                            that.$message({
                                message: res.data.msg,
                                type: "warning",
                            });
                        }
                    })
                    .catch(function(error) {
                    });
            },
            // 二维码保持监听用户信息接口
            async GetuserDEN() {
                let that = this;
                this.$axios({
                        method: "get",
                        url: "/api/shopmini/wap_GetuserDEN.html?data=" + that.dataa + "&ticket=" + that.ticket,
                    }).then(function(res) {
                        that.dataa = res.data;
                        that.val = that.dataa.data.uid;
                        that.reload();
                        // 有手机号
                        if (that.valuea.phone != "") {
                            // 登陆注册框隐藏
                            that.asd = true;
                            that.winReload();
                            that.cellphonea = "手机号：" + that.valuea.phone;
                            clearInterval(that.ding);
                        } else {
                        }
                    })
                    .catch(function(error) {
                    });
            },
            // 向后端传递手机号  验证码  id
            async Msgbdphoneaaa() {
                let that = this;
                that.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Msgbdphone.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            tel: that.deml,
                            code: that.demla,
                            uid: that.val == "" ? that.valuea.uid : that.val,
                        }),
                    })
                    .then(function(res) {
                        if (res.data.statuc == 1) {
                            that.$message({
                                message: "注册绑定成功",
                                type: "success",
                            });

                            if (that.is_pop == 0) {
                                // 关闭登录注册框
                                that.asd = true;
                                that.winReload();
                            } else {
                                that.phoneOk = true;
                            }

                            sessionStorage.setItem("key", JSON.stringify(res.data.data)); //个人信息重新赋值
                            that.valuea = JSON.parse(sessionStorage.getItem("key"));
                            // 关闭倒计时定时器
                            clearInterval(that.clock);


                        } else {
                            that.$message({
                                message: res.data.msg,
                                type: "warning",
                            });
                            // 验证码倒计时重置
                            that.totalTime--;
                            that.isdisable = true;
                            if (that.totalTime <= 0) {
                                that.content = "重新发送验证码";
                                that.demla = "";
                                that.totalTime = 60;
                                that.isdisable = false;
                                clearInterval(that.clock);
                            }
                        }
                    })
                    .catch(function(error) {
                    });
            },

            // 点击头像按钮
            portrait() {
                if (this.judge == "") {
                } else if (this.judge == 1) {
                    this.$router.push({
                        path: "/personal",
                    });
                } else {
                }
            },

            // 获取路径中的参数
            getUrlinfo(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return "";
            },
            // 手机号完成按钮
            accomplish() {
                this.Msgbdphoneaaa();
                // this.session();
                // if(this.is_pop==0){
                // this.router.go(0);
                // }

                // this.reload();
            },
            // 手机号验证码按钮
            code() {

                // 获取is_pop 1-显示客服二维码 0-不显示
                this.is_pop = sessionStorage.getItem('is_pop');
                if (this.is_pop == 1) {
                    this.getKf();

                }
                let that = this;
                if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.deml)) {
                    that.$message({
                        message: "手机号码有误，请重填",
                        type: "warning",
                    });

                    return false;
                } else {
                    this.cellphone();
                }
                this.isdisable = true;
                clearInterval(this.clock); //清除计时器
                this.content = this.totalTime + "s后重新发送"; //这里解决60秒不见了的问题
                this.clock = setInterval(() => {
                    // 获取验证码按钮禁止点击
                    this.totalTime--;
                    this.content = this.totalTime + "s后重新发送";
                    if (this.totalTime == 0) {
                        //当倒计时小于0时清除定时器
                        clearInterval(this.clock);
                        this.content = "重新发送验证码";
                        this.totalTime = 60;
                        // 获取验证码按钮可以点击
                        this.isdisable = false;
                    }
                }, 1000);
            },

            // 行业资讯选项卡
            handleToggle01(index, id) {
                this.active = index;
                this.searchf(id);
            },
            // 短视频选项卡
            handleToggle(index, id) {
                this.activeIndex = index;
                switch (index) {
                    case 0:
                        this.geng = 'prducttype';
                        break;
                    case 1:
                        this.geng = 'prducttypetwo';
                        break;
                    case 2:
                        this.geng = 'prductthree';
                        break;
                }
                this.searchc(id);
            },
            Segment() {
                this.$router.push({
                    name: "Segment",
                });
            },
            // 代运营获取报价按钮
            open() {
                this.showbbb = false;
            },
            hoodeda() {
                this.showbbb = false;
            },
            // 关闭弹窗按钮
            closeaa() {
                this.showbbb = true;
            },
            //短视频更多
            entrance() {
                // this.$router.push("/Short_play");
                if (this.activeIndex == 0) {
                    this.$router.push({
                        name: "Short_play",
                        query: {
                            page: 1,
                        },
                    });
                } else if (this.activeIndex == 1) {
                    this.$router.push({
                        name: "Segment",
                        query: {
                            page: 0,
                        },
                    });
                } else {
                    this.$router.push({
                        name: "Essay",
                        query: {
                            page: 2,
                        },
                    });
                }
            },
            Information() {
                this.$router.push({
                    name: "Information",
                    query: {
                        page: 5,
                        count: this.active,
                    },
                });
            },
            toInfomationContent(id) {
                this.$router.push({
                    name: "Content",
                    query: {
                        page: 5,
                        count: this.active,
                        id,
                        type: 1,
                    },
                });
            },
            // 登录注册按钮
            qq() {
                var that = this;
                this.asd = false;
                this.ding = setInterval(() => {
                    this.GetuserDEN();
                    if (this.dataa.static == 1) {
                        // this.GetuserDEN();
                        this.judge = 1;
                        // 关注成功后隐藏二维码显示手机号验证
                        this.erbtna = false;
                        this.erbtn = true;
                        // this.asd = true;
                        // 加载个人信息
                        this.tourist = false;
                        // 关闭定时器
                        clearInterval(this.ding);
                        // 存储数据
                        // 存储接口个人信息资料
                        sessionStorage.setItem("key", JSON.stringify(this.dataa.data));
                        this.valuea = JSON.parse(sessionStorage.getItem("key"));
                        this.session();
                    }
                }, 1000);
                if (that.val != "" && that.valuea.phone == "") { // 扫码过没有注册账号
                    // 关闭定时器
                    clearInterval(this.ding);
                    that.$message({
                        message: "请注册账号",
                        type: "warning",
                        duration: 2000,
                    });
                    that.asd = false; //注册遮罩显示
                    // 手机号注册显示开始
                    that.erbtna = false;
                    that.erbtn = true;
                    // 手机号显示结束
                    return;
                }
                if (this.valuea.phone != "") {
                    this.asd = true;
                    this.cellphonea = "手机号：" + this.valuea.phone;
                    clearInterval(this.ding);
                } else {
                }
            },
            //sessionStorage获取个人详情
            session() {
                this.valuea = JSON.parse(sessionStorage.getItem("key"));
                if (this.valuea == null || this.valuea.phone == '') {
                    // 如果没有没有key值就不显示个人信息页面
                    this.tourist = true;
                } else {
                    // 显示个人信息页面
                    this.judge = 1;
                    this.tourist = false;
                    this.urlimga = this.valuea.headimgurl;
                    this.uesraname = this.valuea.realname;
                    this.cellphonea = "手机号：" + this.valuea.phone;

                    this.member = this.valuea.class;
                    if (this.valuea.class == 0) {
                        this.member = "暂未开通会员";
                    } else if (this.valuea.class == 1) {
                        this.member = "会员等级：" + "月度会员";
                    } else if (this.valuea.class == 2) {
                        this.member = "会员等级：" + "季度会员";
                    } else if (this.valuea.class == 3) {
                        this.member = "会员等级：" + "年度会员";
                    } else {
                    }
                }
            },
            // 登录注册关闭按钮
            ww(isBox) {
                // 判断点击的是否是整个div 并且是在客服二维码页
                if (isBox == 'isBox' && !this.phoneOk) {
                    return;
                }
                this.asd = true;
                clearInterval(this.ding);
                if (this.is_pop == 1) {
                    this.winReload();
                }
            },
            // 用户协议按钮
            agreement() {
                alert("用户协议");
            },
            //  个人收藏按钮
            coliect() {
                this.$router.push({
                    path: "/Personal",
                    query: {
                        pagaac: 0,
                    },
                });
            },
            //  个人订单按钮
            order() {
                this.$router.push({
                    path: "/Personal",
                    query: {
                        pagaac: 2,
                    },
                });
            },
            //  个人福利按钮
            welfare() {
                this.$router.push({
                    path: "/Personal",
                    query: {
                        pagaac: 1,
                    },
                });
            },
            // 关闭vip弹窗
            onclusa() {
                this.isShow = false;
            },
        },
        beforeDestroy() {
            this.imgrun(2)
        },
        destroyed() {
            window.removeEventListener("scroll", this.showSearch);
            clearTimeout(this.timer);

        },
    };
</script>
<style>
    /* 公司环境 */
    
    .environmentbox {
        width: 100%;
        margin-bottom: 40px;
    }
    
    .environmentbox .compay_title {
        width: 1200px;
        margin: 0 auto 60px;
        display: flex;
    }
    
    .environmentbox .compay_title img {
        margin: auto 0;
        width: 32px;
        height: 32px;
        vertical-align: middle;
    }
    
    .environmentbox .compay_title h1 {
        margin-left: 10px;
    }
    
    .environmentbox .postioneviroment {
        height: 300px;
        margin: 20px 0;
        width: 1200px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
    }
    
    .environmentbox .postioneviroment .centeenvironment {
        display: flex;
        position: absolute;
    }
    
    .environmentbox .postioneviroment .centeenvironment .list_environm {
        width: 200px;
        margin-right: 30px;
        margin-right: 40px;
        display: inline-table;
    }
    
    .environmentbox .postioneviroment .centeenvironment .list_environm .outerimgcop {
        overflow: hidden;
        width: 336px;
        height: 236px;
    }
    
    .environmentbox .postioneviroment .centeenvironment .list_environm .outerimgcop img {
        width: 100%;
        height: 100%;
        transition-duration: 1.2s;
    }
    
    .environmentbox .postioneviroment .centeenvironment .list_environm:hover img {
        transform: scale(1.2);
    }
    
    .environmentbox .postioneviroment .centeenvironment .list_environm p {
        margin-top: 20px;
        font-size: 20px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: center;
        color: #000000;
    }
    /* 客户评价 */
    
    .evaluatebox_cebte {
        width: 100%;
        background: #f6f8fa;
        padding-bottom: 60px;
    }
    
    .evaluatebox {
        width: 1200px;
        margin: auto;
        overflow: hidden;
    }
    
    .evaluatebox .evaluation_title {
        width: 100%;
        padding: 60px 0 40px;
        display: flex;
        flex-direction: column;
    }
    
    .evaluatebox .evaluation_title p:nth-child(1) {
        font-size: 24px;
        margin: auto;
        font-family: MicrosoftYaqiHei, MicrosoftYaqiHei-Bold;
        font-weight: 500;
        text-align: center;
        color: #222222;
    }
    
    .evaluatebox .evaluation_title p:nth-child(2) {
        margin-top: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: center;
        color: #222222;
    }
    
    .evaluateboxbanner {
        width: 100%;
        margin: auto;
    }
    
    .evaluateboxbanner .el-carousel--horizontal {
        overflow: visible;
    }
    
    .evaluateboxbanner #courbannebox {
        overflow: visible;
    }
    
    .el-carousel__container {
        position: relative !important;
        width: 100%;
        margin: auto;
    }
    
    .evaluateboxbanner .el-carousel__item {
        height: 220px;
        margin: 0 00px !important;
        width: 90%;
        background-color: #fff !important;
        border-radius: 10px;
    }
    
    .evaluateboxbanner .evaluateboxbanner .el-carousel--horizontal {
        overflow: visible !important;
    }
    
    .evaluateboxbanner .el-carousel__item:nth-child(2n) {
        margin: auto;
        left: 5%;
        right: 5%;
    }
    
    .evaluateboxbanner .el-carousel__item:nth-child(2n+1) {
        left: 5%;
        right: 5%;
        margin: auto;
        width: 90%;
    }
    
    .evaluateboxbanner .el-carousel__container button {
        display: block !important;
        top: 110px;
        width: 50px;
        height: 50px;
    }
    
    .evaluateboxbanner .el-carousel__container button:hover {
        background-color: #fff;
    }
    
    .evaluateboxbanner .el-carousel__container button:hover i::before {
        font-size: 20px;
        font-weight: 600;
        color: red;
    }
    
    .evaluateboxbanner .el-carousel__container .el-carousel__arrow--left {
        left: 30px;
    }
    
    .evaluateboxbanner .el-carousel__container .el-carousel__arrow--right {
        right: 30px;
    }
    
    .list_bannerbox {}
    
    .list_bannerbox .centheadimg {
        margin: auto;
        display: flex;
    }
    
    .list_bannerbox .centheadimg img {
        width: 60px;
        height: 60px;
        margin: -30px auto 0;
        border-radius: 100%;
    }
    
    .list_bannerbox .conteninfo {
        display: flex;
        flex-direction: column;
    }
    
    .list_bannerbox .conteninfo p:nth-child(1) {
        font-size: 24px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 600;
        text-align: center;
        color: #000000;
        margin-top: 30px;
    }
    
    .list_bannerbox .conteninfo p:nth-child(2) {
        font-size: 16px;
        text-indent: 2em;
        line-height: 24px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 500;
        text-align: left;
        color: #000000;
        margin: 10px 42px 0;
    }
    
    .list_bannerbox .douyinmesg {
        display: flex;
    }
    
    .list_bannerbox .douyinmesg .imgbox {
        margin: 45px auto;
        background-color: #fff;
        padding: 0 10px;
    }
    
    .list_bannerbox .douyinmesg .imgbox img {}
    /* input吸顶效果 */
    
    .box_asd {
        width: 400px;
        /* border: 1px red solid; */
    }
    
    .fixedSearch {
        position: fixed;
        z-index: 20;
        height: 82px !important;
        background: rgb(255, 255, 255);
        top: 60px;
    }
    
    .fixedSearchNext {
        height: 90px !important;
    }
    /* 吸顶效果结束 */
    /* 顶部 */
    
    .head-tou {
        background-color: red;
    }
    
    .content-box {
        width: 100%;
        min-width: 1200px;
        height: 400px;
        padding-top: 80px;
    }
    
    .content {
        width: 1200px;
        height: 400px;
        margin: auto;
        display: flex;
    }
    /* 轮播图 */
    
    .slideshow {
        width: 900px;
        height: 400px;
    }
    
    .block_one {
        height: 400px;
        border-radius: 20px;
        overflow: hidden;
        cursor: pointer;
    }
    
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }
    
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    
    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    /* 个人中心 */
    
    .personal-center {
        width: 290px;
        height: 400px;
        margin-left: 20px;
        border-radius: 20px;
        background-image: url("../assets/home/2.png");
        background-size: 100%;
    }
    
    .head-box {
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    }
    
    .head-img {
        width: 108px;
        height: 108px;
        border-radius: 50%;
        background-color: #fec675;
    }
    
    .head-content {
        width: 100px;
        height: 100px;
        /* margin-bottom: 10px; */
    }
    
    .head-content img {
        margin: 0 auto;
        width: 107px;
        height: 107px;
        background-color: red;
        border-radius: 50%;
        /* border: 2px #fec675 solid; */
        vertical-align: top;
    }
    /* 游客 */
    
    .visitor_box {
        color: green;
        /* display: block; */
    }
    
    .visitor {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
    
    .visitor-font {
        color: #ffffff;
        font-size: 20px;
        font-family: PingFang HK;
    }
    
    .visitor span {
        font-size: 20px;
    }
    
    .visitor p {
        display: inline-block;
        font-size: 20px;
    }
    /* 登录注册按钮 */
    
    .enter-login-button {
        width: 100%;
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }
    
    .enter-login-button-box button {
        width: 152px;
        height: 50px;
        border-radius: 25px;
        background-color: #f62959;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
    }
    /* 已登录后的样式 */
    
    .Is_now_box {
        width: 237px;
        height: auto;
        margin: auto;
        /* display: none; */
    }
    /* 昵称 */
    
    .nickname_box {
        width: 100%;
        display: flex;
        justify-content: stretch;
    }
    
    .nickname {
        margin: auto;
        display: flex;
        align-items: stretch;
    }
    
    .nickname i img {
        width: 26px;
        height: 26px;
        vertical-align: bottom;
        margin-right: 4px;
    }
    
    .nickname p {
        font-size: 20px;
        color: white;
    }
    /* 账号 */
    
    .account_box {
        margin-top: 15px;
        text-align: center;
    }
    
    .account_box p {
        color: white;
        font-size: 16px;
    }
    /* 我的下面的内容 */
    
    .I_bottom {
        width: calc(100% - 60px);
        height: 100px;
        display: flex;
        padding: 0 30px;
        justify-content: space-between;
        margin-top: 20px;
    }
    
    .I_bottom_content {
        width: 33.3%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    .I_bottom_content ul li {
        font-size: 14px;
        color: #ffffff;
        text-align: center;
    }
    /* 搜索 */
    
    .seek {
        width: 100%;
        height: 130px;
        min-width: 1200px;
    }
    
    .seek-input {
        width: 1200px;
        height: 130px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .seek-box {
        width: 750px;
        height: 60px;
        display: flex;
        align-items: center;
    }
    
    .seek-one {
        display: flex;
        align-items: center;
        border: 1px #f62959 solid;
        border-radius: 8px;
    }
    
    .seek-one-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }
    
    .seek-one-icon img {
        width: 100%;
    }
    
    .seek-one-input {
        width: 590px;
        display: inline-block;
    }
    
    .seek-one-input input {
        width: 99%;
        line-height: 38px;
        font-size: 16px;
        color: #999999;
        vertical-align: middle;
        background: none;
    }
    
    .seek-one-input input::-webkit-input-placeholder {
        color: #999999;
    }
    
    .seek-one-button {
        width: 114px;
        height: 40px;
    }
    
    .seek-one-button button {
        width: 114px;
        height: 40px;
        background-color: #f62959;
        color: #ffffff;
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
        font-size: 18px;
    }
    
    .seek_input_font {
        width: 540px;
        position: relative;
        top: -10px;
    }
    
    .seek_input_font ul {
        display: flex;
        justify-content: space-between;
    }
    
    .seek_input_font ul li {
        cursor: pointer;
        font-size: 14px;
        color: #999999;
    }
    
    .seek_input_font ul li:hover {
        color: #f62959;
    }
    /* 短据本 */
    
    .play-box {
        width: 100%;
        /* height: 850px; */
        min-width: 1200px;
        max-height: 850px;
        background-color: #fafafa;
    }
    
    .play-content {
        width: 1200px;
        height: 1000px;
        margin: auto;
    }
    /* 标题 */
    
    .play-title {
        width: 100%;
        padding: 20px 0px 20px 0px;
    }
    
    .play-title div {
        display: inline-block;
        margin-left: 10px;
    }
    
    .play-title i {
        display: inline-block;
        vertical-align: middle;
    }
    
    .play-title img {
        width: 32px;
        height: 32px;
    }
    
    .play-title h1 {
        display: inline-block;
        vertical-align: bottom;
        font-weight: inherit;
        margin-left: 10px;
        letter-spacing: 2px;
    }
    
    .play-title p {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
    }
    
    .play-title-font-tow {
        height: 34px;
    }
    /* 选项卡 */
    /* 选项卡 */
    
    .btnAtive {
        background: #f62959;
        color: #fff;
        border-radius: 10px;
    }
    
    .btnAtive01 {
        background: #f62959;
        color: #fff;
        border-radius: 10px;
    }
    
    .container {
        width: 1200px;
    }
    
    .container>div {
        width: 1200px;
    }
    
    .tab {
        width: 100%;
        min-width: 1200px;
        /* font-size: 16px; */
        color: #000000;
        position: relative;
        margin-bottom: 20px;
    }
    
    .tab>ul {
        height: 60px;
        line-height: 60px;
        background-color: #faeff1;
        display: flex;
        align-items: center;
    }
    
    .tab>ul>li {
        width: 180px;
        height: 60px;
        font-size: 18px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        border: 1px #faeff1 solid;
    }
    
    .tab>ul>li:nth-last-of-type(1) {
        width: auto;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        border-radius: 20px;
        font-size: 16px;
        color: #ffffff !important;
        background-color: #f62959;
        position: absolute;
        /* top: 25%; */
        right: 30px;
    }
    
    .tab-1 {
        margin: 20px 20px 0px 0px;
        padding-left: 10px;
        padding-top: 2px;
        width: 380px;
        border-radius: 8px;
        background-color: #ffffff;
        cursor: pointer;
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.08);
        box-sizing: border-box;
        transition: 0.3s;
        position: relative;
    }
    
    .tab-1:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.15);
    }
    
    .tab-title>p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .tab-title>p:hover {
        color: #f62959;
    }
    
    .tab_image {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab_image img {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab-title-font {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-top: 10px;
    }
    
    .tab-one {
        width: 6px;
        height: 22px;
        border-radius: 25px;
        vertical-align: middle;
        margin: 0px 10px 0px 10px;
        background-color: #f62959;
    }
    
    .tab-title {
        vertical-align: middle;
        font-size: 20px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    /* 文字内容 */
    
    .tab-font {
        width: 86%;
        height: 66px;
        overflow: hidden;
        /* background-color: tan; */
        margin: auto;
        font-size: 14px;
        color: #666666;
    }
    
    .tab-font p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    /* 标签 */
    
    .label {
        width: 86%;
        /* background-color: red; */
        height: 30px;
        margin: auto;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px #fee9ee solid;
    }
    
    .label div {
        padding: 3px 8px 3px 8px;
        display: inline-block;
        background-color: #fff7f2;
        color: #ff5c0d;
        border-radius: 2px;
        margin: 4px;
    }
    /* 个人信息 */
    
    .personal {
        width: 86%;
        margin: auto;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;
    }
    
    .personal p:nth-of-type(1) {
        margin: 0px 10px 0px 0;
    }
    
    .personal img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
    
    .personal p:nth-of-type(2) {
        width: 30%;
    }
    
    .personal p:nth-of-type(3) {
        width: 60%;
        text-align: right;
    }
    /* 短视频定制服务的盒子 */
    
    .customization {
        width: 100%;
        min-width: 1200px;
    }
    /* 短视频定制服务的盒子 */
    
    .customization-background {
        position: absolute;
    }
    
    .customization-background img {
        width: 100%;
        height: 600px;
        min-width: 1200px;
    }
    /* 短视频定制服务的内容 */
    
    .customization-box {
        width: 1200px;
        height: 600px;
        margin: auto;
        position: relative;
    }
    
    .customization-content {
        width: 1200px;
        height: 410px;
        border-radius: 20px;
        background-image: url(../assets/home/5.png);
        background-size: 100%;
        display: flex;
        margin-top: 40px;
    }
    /* 短视频左边的文字 */
    
    .customization-font {
        width: 500px;
        height: 400px;
        border-radius: 20px 0px 0px 20px;
    }
    /* 标题 */
    
    .customization-font-title {
        width: 87.2%;
        margin: auto;
        display: flex;
        padding: 20px 0px 20px 0px;
    }
    
    .customization-font-title h1 {
        font-weight: 500;
        margin-left: 10px;
        color: #ffffff;
        letter-spacing: 2px;
    }
    /* 描述 */
    
    .describe {
        width: 87.2%;
        line-height: 70px;
        margin: auto;
        color: #ffffff;
        font-size: 20px;
        letter-spacing: 4px;
    }
    
    /* 分割线 */
    
    .cut-off_rule-box {
        width: 87.2%;
        margin: auto;
    }
    
    .cut-off_rule {
        width: 120px;
        height: 6px;
        background-color: #ffffff;
    }
    /* 下面的图标 */
    
    .customization-icon {
        width: 87.2%;
        height: 232px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        align-items: center;
    }
    
    .customization-icon p {
        color: #ffffff;
    }
    /* 短视频右边的视频 */
    
    .customization-mp4 {
        width: 713px;
        height: 410px;
        border-radius: 0px 20px 20px 0px;
        background-color: #f62959;
        /* overflow: hidden; */
    }
    
    .customization-mp4 video {
        width: 713px;
        height: 410px;
        border-radius: 0px 20px 20px 0px;
        outline: none;
        border: none;
        cursor: pointer;
    }
    /* 按钮 */
    
    .customization-button {
        width: 1200px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* background-color: red; */
    }
    
    .customization-button button {
        width: 188px;
        height: 60px;
        background-color: #f62959;
        border-radius: 30px;
        font-size: 18px;
        color: #ffffff;
        box-shadow: 0px 0px 10px 2px rgba(246, 41, 89, 0.2);
        cursor: pointer;
    }
    /* 代运营 */
    
    .thirdpartnar-box {
        width: 100%;
        min-width: 1200px;
        margin: auto;
    }
    
    .thirdpartnar-content {
        width: 1200px;
        margin: auto;
    }
    /* 标题 */
    
    .thirdpartnar-title {
        width: 100%;
        padding: 30px 0px 30px 0px;
    }
    
    .thirdpartnar-title i,
    .thirdpartnar-title h1,
    .thirdpartnar-title p {
        display: inline-block;
    }
    
    .thirdpartnar-title>i>img {
        width: 32px;
        height: 32px;
        vertical-align: middle;
    }
    
    .thirdpartnar-title h1 {
        vertical-align: middle;
    }
    
    .thirdpartnar-title p {
        vertical-align: bottom;
    }
    
    .thirdpartnar-title h1,
    .thirdpartnar-title p {
        margin-left: 10px;
    }
    /* 内容 */
    /* 盒子内容 */
    
    .thirdpartnar-content-one {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        height: 620px;
    }
    
    .thirdpartnar-content-one .list_lfinfo {
        width: 46%;
        overflow-y: auto;
        height: 100%;
        /* overflow-y: hidden */
    }
    
    .thirdpartnar-content-one .list_lfinfo::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo .listbannerinfo {
        width: 90%;
        padding: 0 40px;
        display: flex;
        margin: auto;
        padding-top: 20px;
        margin-top: 20px;
        box-sizing: border-box;
        box-shadow: 0 0 0 5px #ffffff;
        height: 142px;
        margin-bottom: 40px;
        border-radius: 8px;
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo .listbannerinfo:hover {
        box-sizing: border-box;
        box-shadow: 0px 0px 20px 0px rgb(246 41 89 / 15%);
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo .listbannerinfotwo {
        box-sizing: border-box;
        box-shadow: 0px 0px 20px 0px rgb(246 41 89 / 15%);
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo .listbannerinfo .listheadimg {
        width: 40px;
        height: 40px;
        margin-right: 24px;
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo .listbannerinfo .listheadimg img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo .listbannerinfo .listheadmesage {
        width: 80%;
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo .listbannerinfo .listheadmesage p:nth-child(1) {
        line-height: 40px;
        font-size: 20px;
        font-family: MicrosoftYaqiHei, MicrosoftYaqiHei-Regular;
        font-weight: 600;
        text-align: left;
        color: #000000;
    }
    
    .thirdpartnar-content-one .list_lfinfo .lfinoutinfo .listbannerinfo .listheadmesage p:nth-child(2) {
        font-size: 15px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 26px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
    
    .thirdpartnar-content-one .list_rfbanner {
        width: 50%;
        height: 600px;
        margin: auto;
        background-image: url("../assets/aaa/bannerbackimg.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel {
        height: 100% !important;
    }
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel .el-carousel__container {
        height: 89% !important;
    }
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel__item {
        height: 580px;
        display: flex;
        background-color: rgba(0, 0, 0, 0);
    }
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel__item img {
        width: 280px;
        height: 540px;
        margin: auto;
    }
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel .el-carousel__container .is-active {
        width: 300px;
        margin: auto;
    }
    
    .el-carousel .el-carousel__indicators {}
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel .el-carousel__indicators {
        position: relative !important;
        left: 0;
        top: 20px;
    }
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel .el-carousel__indicators li button {
        background-color: #999999;
        width: 15px;
        height: 3px;
    }
    
    .thirdpartnar-content-one .list_rfbanner .el-carousel .el-carousel__indicators .is-active button {
        width: 15px;
        background-color: #F6295A !important;
        height: 3px;
    }
    
    .thirdpartnar-one {
        width: 386px;
        height: 466px;
        border: 1px #f62959 solid;
        border-radius: 20px;
        text-align: center;
        transition: 0.3s;
    }
    
    .thirdpartnar-one:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.15);
    }
    /* 标题 */
    
    .thirdpartnar-one-title img {
        vertical-align: middle;
        display: inline-block;
    }
    
    .thirdpartnar-one-title {
        margin-top: 40px;
    }
    
    .thirdpartnar-one-title h2 {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }
    
    .thirdpartnar-middle {
        height: 170px;
    }
    
    .thirdpartnar-middle div {
        margin-top: 20px;
        font-size: 18px;
        line-height: 30px;
    }
    /* 价格 */
    
    .price p {
        height: 20px;
        color: #faad00;
    }
    
    .price p:nth-of-type(1) {
        font-size: 18px;
        display: inline-block;
        color: #faad00;
    }
    
    .price p:nth-of-type(2) {
        font-size: 30px;
        display: inline-block;
        color: #faad00;
        letter-spacing: 2px;
    }
    
    .thirdpartnar-one p {
        font-size: 18px;
        line-height: 30px;
    }
    
    .thirdpartnar-one p:nth-of-type(1) {
        margin-top: 20px;
    }
    
    .thirdpartnar-one p:nth-of-type(7) {
        color: #999999;
        line-height: 50px;
    }
    
    .gray p {
        color: #999999;
        font-size: 14px;
    }
    
    .thirdpartnar {
        width: 100%;
        margin-top: 6px;
    }
    
    .thirdpartnar button {
        width: 170px;
        height: 50px;
        border-radius: 25px;
        color: #ffffff;
        background-color: #f62959;
        font-size: 18px;
    }
    /* .thirdpartnar button:hover {
  transform: scale(1.2);
} */
    /* 行业资讯 */
    
    .industry-information-box {
        width: 100%;
        /* height: 1000px; */
        min-width: 1200px;
        min-height: 450px;
        max-height: 1000px;
    }
    
    .industry-information-content {
        width: 1200px;
        margin: auto;
    }
    
    .industry-information-content .datainfbox {
        display: flex;
    }
    
    .industry-information-content .datainfbox .listinfdata {
        width: 33%;
        display: flex;
    }
    
    .industry-information-content .datainfbox .listinfdata:hover {
        background-color: #F6295A;
    }
    
    .industry-information-content .datainfbox .listinfdata .centedata {
        margin: 60px auto 40px;
        display: flex;
        flex-direction: column;
    }
    
    .industry-information-content .datainfbox .listinfdata .centedata .listtihaindle {
        display: flex;
        margin: auto;
    }
    
    .industry-information-content .datainfbox .listinfdata .centedata .listtihaindle img {
        width: 20px;
        margin: auto 10px auto 0;
        height: 18px;
    }
    
    .industry-information-content .datainfbox .listinfdata:hover .centedata .listtihaindle p {
        color: #fff;
    }
    
    .industry-information-content .datainfbox .listinfdata .centedata .listtihaindle p {
        font-size: 20px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 600;
        text-align: center;
        color: #222222;
    }
    
    .industry-information-content .datainfbox .listinfdata .centedata .listdatnumber {
        display: flex;
        margin: 20px 0;
    }
    
    .industry-information-content .datainfbox .listinfdata:hover .centedata .listdatnumber p {
        color: #fff;
    }
    
    .industry-information-content .datainfbox .listinfdata .centedata .listdatnumber p {
        margin: auto;
        font-size: 40px;
        font-family: MicrosoftYaqiHei, MicrosoftYaqiHei-Bold;
        font-weight: 700;
        text-align: center;
        color: #222222;
    }
    
    .industry-information-content .datainfbox .listinfdata .centedata .listdatetitme {
        display: flex;
    }
    
    .industry-information-content .datainfbox .listinfdata:hover .centedata .listdatetitme p {
        color: #fff;
    }
    
    .industry-information-content .datainfbox .listinfdata .centedata .listdatetitme p {
        font-size: 14px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: center;
        color: #222222;
    }
    /* 
    
    <div class="listinfdata">
             <div class="centedata">
            
             
               <div class="listdatetitme">
                 <p>仅3年我们服务各行业客户超36万人</p>
               </div>
             </div>
          </div> */
    /* 选项卡内容 */
    
    .container>div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .container-one {
        width: 550px;
        height: 176px;
        border: 1px #f0f3f5 solid;
        border-radius: 10px;
        display: flex;
        overflow: hidden;
        margin-top: 30px;
        cursor: pointer;
        transition: 0.3s;
    }
    
    .container-one:hover {
        transform: scale(1.1);
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.15);
    }
    /* =================================================================== */
    
    .moreBtn:hover {
        color: #f62959;
    }
    
    .moreBtn {
        position: absolute;
        width: 100%;
        right: 0;
        text-align: right;
        box-sizing: border-box;
        padding-right: 10px;
        color: rgb(180, 180, 180);
        margin-top: -20px;
        font-size: 14px;
        background: -moz-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
    }
    
    .container-one-left {
        width: 260px;
        height: 176px;
    }
    
    .container-one-left img {
        width: 100%;
        vertical-align: middle;
    }
    
    .container-one-right {
        width: 290px;
        margin-left: 10px;
        line-height: 22px;
    }
    
    .container-one-right h3 {
        width: 94%;
        margin: auto;
        padding: 20px 0px 10px 0px;
        font-size: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .container-one-right h3:hover {
        color: #f62959;
    }
    
    .container-one-right p {
        width: 94%;
        margin: auto;
        font-size: 14px;
        color: #333333;
    }
    
    .container-one-right p:nth-of-type(1) {
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: elipsis;
    }
    
    .container-one-right p:nth-of-type(2) {
        line-height: 40px;
        /*   */
        text-align: right;
    }
    /* 登录注册 */
    
    .enter_box {
        width: 100vw;
        height: 100vh;
        /* background-color: red; */
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(40px);
        z-index: 999;
        position: fixed;
        top: 0;
    }
    
    .demo {
        display: none;
    }
    
    .close_button_box {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: #a7aab3;
        position: absolute;
        right: 0;
        top: -44px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .close_button_box img {
        width: 34px;
        height: 34px;
        vertical-align: middle;
        transition: 0.3s;
    }
    
    .close_button_box img:hover {
        transform: rotate(90deg);
    }
    
    .enter {
        width: 100vw;
        height: 100vh;
        /* background-color: red; */
        background-color: rgba(255, 255, 255, 0.2);
    }
    /* 中间内容 */
    
    .enter_content {
        width: 800px;
        height: 480px;
        background-color: rgba(255, 255, 255, 1);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -240px;
        margin-left: -400px;
        display: flex;
        z-index: 999;
    }
    /* 左 */
    
    .enter_left {
        width: 50%;
    }
    
    .enter_left img {
        width: 400px;
        height: 480px;
        vertical-align: middle;
    }
    /* 右 */
    
    .enter_right {
        width: 50%;
    }
    /* 标题 */
    
    .enter_title {
        width: 100%;
        height: 90px;
        line-height: 90px;
        border-bottom: 1px #e5e5e5 solid;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: unset;
    }
    /* 文字 */
    
    .enter_text_box {
        width: 100%;
        height: 40px;
        margin-top: 20px;
    }
    
    .enter_text {
        width: 86.5%;
        height: 40px;
        border-radius: 20px;
        background-color: #fee9ee;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
    
    .enter_text p {
        color: #333333;
        font-size: 14px;
    }
    
    .enter_text span {
        color: #f62959;
    }
    /* 二维码 登录 验证码*/
    /* 二维码 */
    
    .QR_code_box {
        width: 100%;
        height: 222px;
        background-origin: 1px red solid;
        margin-top: 20px;
    }
    
    .QR_code {
        width: 222px;
        height: 222px;
        border: 1px solid #707070;
        margin: auto;
    }
    
    .QR_code img {
        width: 222px;
        height: 222px;
    }
    /* 手机号码 */
    
    .phone {
        width: 308px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #f62959;
        border-radius: 25px;
        box-shadow: 0px 0px 6px 0px rgba(246, 41, 89, 0.2);
        margin: auto;
        display: flex;
        align-items: center;
    }
    /* 前面的图标 */
    
    .number_icon {
        width: 19%;
        height: 30px;
        border-right: 1px #e5e5e5 solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .number_icon i,
    .number_icon i img {
        width: 20px;
        height: 20px;
    }
    /* 输入框 */
    
    .number-input {
        width: 70%;
        margin-left: 10px;
        -webkit-user-select: text;
        user-select: text;
    }
    
    .number-input input {
        width: 90%;
        height: 30px;
        -webkit-user-select: text;
        user-select: text;
    }
    /* 验证码 */
    
    .verify {
        width: 308px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #f62959;
        border-radius: 25px;
        box-shadow: 0px 0px 6px 0px rgba(246, 41, 89, 0.2);
        margin: auto;
        display: flex;
        align-items: center;
        margin-top: 20px;
        -webkit-user-select: text;
        user-select: text;
    }
    /* 前面的图标 */
    
    .verify_icon {
        width: 19%;
        height: 30px;
        /* border-right: 1px #E5E5E5 solid; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .verify_icon i,
    .verify_icon i img {
        width: 20px;
        height: 20px;
    }
    /* 输入框 */
    
    .verify-input {
        width: 40%;
        margin-left: 10px;
    }
    
    .verify-input input {
        width: 86%;
        height: 30px;
        -webkit-user-select: text;
        user-select: text;
    }
    /* 获取验证码的按钮 */
    
    .verify_button {
        margin-right: 8px;
    }
    
    .verify_button button {
        width: 107px;
        height: 34px;
        color: white;
        background: #f62959;
        border-radius: 17px;
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.1);
    }
    /* 登录完成的按钮 */
    
    .complete {
        width: 308px;
        height: 50px;
        margin: auto;
    }
    
    .complete button {
        width: 308px;
        height: 50px;
        background: #f62959;
        color: white;
        border-radius: 25px;
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
        margin-top: 20px;
        font-size: 18px;
    }
    /* 下面的文字 */
    
    .enter_bottom_box {
        width: 100%;
        height: 30px;
        margin-top: 20px;
    }
    
    .enter_bottom {
        width: 74.5%;
        height: 20px;
        margin: auto;
    }
    
    .enter_bottom input {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px #999999 solid;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
    }
    
    .enter_bottom input:checked {
        background-image: url(../assets/home/129.png);
        background-size: 100%;
        background-repeat: no-repeat;
        border: none;
    }
    
    .enter_bottom label {
        color: #333333;
        font-size: 14px;
        margin-left: 10px;
    }
    
    .enter_bottom label span {
        color: #f62959;
        cursor: pointer;
    }
    
    button:hover {
        background-color: #d6244e;
    }
    /* 广告 */
    
    .advertising_box {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        position: fixed;
        top: 0;
        /* display: none; */
    }
    
    .advertising {
        width: 600px;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -300px;
        margin-left: -300px;
        /* background-color: red; */
    }
    
    .advertising_image {
        width: 600px;
        height: 490px;
    }
    
    .advertising_image img {
        width: 600px;
        height: 490px;
    }
    /* 按钮 */
    
    .advertising_button {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .advertising_button button {
        width: 150px;
        height: 50px;
        background: linear-gradient(90deg, #eac583, #dca545);
        border-radius: 25px;
        box-shadow: 0px 3px 6px 0px rgba(159, 116, 68, 0.1);
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
    }
    
    .occlude {
        font-size: 25px;
        line-height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: 50px;
        top: 80px;
    }
    /* 广告结束 */
    /* 右 */
    
    .enter_right {
        width: 50%;
        display: none;
    }
    
    .rnter_righta {
        width: 50%;
        display: none;
    }
    /* 代运营获取报价按钮 */
    
    .customer {
        width: 100vw;
        height: 100vh;
        /* background-color: red; */
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(20px);
        z-index: 999;
        position: fixed;
        top: 0;
    }
    
    .entera {
        width: 100vw;
        height: 100vh;
        /* background-color: red; */
        background-color: rgba(255, 255, 255, 0.2);
    }
    
    .enter_contenta {
        width: 260px;
        height: 260px;
        background-color: rgba(255, 255, 255, 1);
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
        border-radius: 15px;
    }
    
    .customera {
        display: none;
    }
    
    .customer button {
        width: 55px;
        height: 55px;
        font-size: 40px;
        border-radius: 50%;
        position: absolute;
        top: -60px;
        left: 300px;
    }
    
    .imgsrca {
        width: 100%;
        height: 80%;
        text-align: center;
        padding-top: 20px;
    }
    
    .imgsrca img {
        width: 65%;
        height: auto;
        margin: 0 auto;
    }
    
    .enter_contenta p {
        text-align: center;
        /* margin-top: 10px; */
        font-size: 20px;
        font-weight: bold;
    }
</style>

<!-- --轮播图 -->
<style>
    .el-carousel__container button {
        display: none !important;
    }
    /* 客服二维码 */
    
    .kferwm {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
    }
    
    .kferwm img {
        margin-bottom: 20px;
        max-width: 200px;
        max-height: 200px;
    }
    
    .kferwm .text {
        display: flex;
        align-items: center;
    }
    
    .kferwm>div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .textOne {
        display: flex;
    }
    
    .kferwm .text img {
        width: 20px;
        height: 20px;
        margin-bottom: 0px;
        margin-right: 5px;
    }
</style>